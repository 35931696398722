import http from "./httpservice";
import helpr from "./cryptos";
var atob = require("atob");

const apiEndpoint = "/api";

export async function getinsta(obj) {
  const validaobj = helpr.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + "/checkout", {
    enc: validaobj,
  });
  return helpr.decryptobj(data);
}
export async function topwalletphonepay(obj) {
  const validaobj = helpr.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + "/checkout", {
    enc: validaobj,
  });
  return helpr.decryptobj(data);
}
export async function topwalletotppay(obj) {
  const validaobj = helpr.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + "/checkout_otp", {
    enc: validaobj,
  });
  return helpr.decryptobj(data);
}
export async function resendOTP(obj) {
  const validaobj = helpr.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + "/checkout_resend_otp", {
    enc: validaobj,
  });
  return helpr.decryptobj(data);
}

export default {
  getinsta,
  topwalletphonepay,
  topwalletotppay,
  resendOTP,
};
