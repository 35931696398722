import React from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import Pop from "./common/pop";

import MainComponent from "./components";
import Home from "./components/checkout/home";
import Insta from "./components/checkout/insta";
import CheckOut from "./components/checkoutpage";

function App() {
  return (
    <React.Fragment>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" exact element={<Home />} />
        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default App;
