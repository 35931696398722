import { publicIpv4 } from "public-ip";
import queryString from "query-string";
import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import ScaleLoader from "react-spinners/ScaleLoader";
import { toast } from "react-toastify";
import Header from "../../common/header";
import PopUp from "../../common/pop";
import { pushRoute } from "../../common/pushRoute";
import { getinsta } from "../../services/checkoutService";
import httpservice from "../../services/httpservice";
import NoData from "../nodata";
import Insta from "./insta";
import Topchek from "./topchek";
var recaptchaRef = React.createRef();
var capt = process.env.REACT_APP_CAPTCHA;
class Home extends Component {
  state = { insta: false };

  componentDidMount = async () => {
    // const token = await recaptchaRef.current.execute();
    // await this.setState({ token });
    // console.log(token);
    // await httpservice.setJwt(token);
    const IP = await publicIpv4();

    await this.setState({ ip: IP });

    // var location_search =
    //   "http://localhost:3000?hash=U2FsdGVkX18oCG62MYzaaMWLp3%2FlFMeKXXxx22YW7UPLbh2UfpayBIXD68HR4bfruX5zFsQXtTXPDUoJew54FTA5JDh0nuA%2BqqI8FaNslG2csllwge2jicsbUjWYofdjWULth6p6HWyhDzqhp5iFPApKu6XjEM%2F%2BO841%2FelN%2BhVFKbXGox2vm8Xr84W7NiJJAai6HHIBLudnaSkzTM6HYcRr%2F7qS8ChhPq4EGt%2F559U5pV6xLpXjW0gwwuTvc2jebZgxNgwpBbNjijOYyy5barn6PwYuImWEffQiS47yKHTYvP3Tq2BXaN%2FO454aUGmXCxbQsnwHaxE85f9EvArJE9xIe9bLuXrcY2HnX8XqoFs%3D&apikey=U2FsdGVkX1960GCCFPSOxcrksCElPpOd1%2BBs3XWH4CZoIBlfdaGymxiU7CjDVAmtIjE9ZVkTAoBa4Ka6VL%2BGHg%3D%3D";
    const parsed = queryString.parse(this.props?.location?.search);

    if (parsed && parsed?.apikey && parsed?.hash) {
      await this.setState({
        securepage: false,
        apikey: encodeURIComponent(parsed?.apikey.toString("base64")),
        hash: encodeURIComponent(parsed?.hash.toString("base64")),
      });
    } else {
      this.setState({ securepage: true });
    }
  };

  instapaychk = async (type) => {
    const token = await recaptchaRef.current.execute();
    httpservice.setJwt(token);

    this.setState({ loader: true, type: type });

    try {
      var instaobj = {
        type: type,
        hash: this.state.hash,
        api_key: this.state.apikey,
      };
      this.setState({ loader: false });

      const res = await getinsta(instaobj);

      if (res) {
        this.setState({ insta: true, loader: false, instares: res });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data, {
          position: toast.POSITION.TOP_RIGHT,
        });
        await this.setState({ loader: false, securepage: true });
      } else if (ex.response && ex.response.status === 401) {
        toast.error(ex.response.data, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };
  instapage = async () => {};

  topwalletpaychk = async (type) => {
    const token = await recaptchaRef.current.execute();
    httpservice.setJwt(token);
    const { navigate } = this.props;
    this.setState({ loader: true, type: type, top: true, insta: false });
  };
  render() {
    if (!this.state.securepage) {
      return (
        <React.Fragment>
          <Header />
          <section className="about vision-mission pt-5">
            <div className="container">
              <div className="row  aos-init aos-animate" data-aos="fade-up">
                <div
                  className="col-md-6 mob_order  pt-lg-2"
                  style={{ marginTop: "6rem" }}
                >
                  <img
                    src={require("../../assets/images/eco-1.png")}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                {!this.state.insta && !this.state.top ? (
                  <div className="col-md-6  pt-4 mob_insta">
                    <div className="offset-md-1">
                      <h4 className="tit_text text-white">
                        Select an option you would like to proceed
                      </h4>
                      {!this.state.loader ? (
                        <div className="container-fluid">
                          <div className="text-center mob_left">
                            <div className="col-11 col-md-7 mb-md-3 mb-2 mx-3 text-center">
                              <div
                                className="bg-white insta_card"
                                onClick={() => this.instapaychk("insta")}
                              >
                                <h5
                                  className="card-title"
                                  style={{ alignSelf: "center" }}
                                >
                                  Pay With
                                </h5>
                                <img
                                  src={require("../../assets/images/instapay.png")}
                                  alt="logo"
                                />
                                {/* <p
                                  className="card-text text-dark mt-md-2"
                                  style={{ fontWeight: "600" }}
                                >
                                  fee : 15₱
                                </p> */}
                              </div>
                            </div>
                            <div className="col-11 col-md-7 mb-md-3 mb-2 mx-3 text-center">
                              <div
                                className="bg-white tw_card"
                                onClick={() =>
                                  this.topwalletpaychk("topwallet")
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <h5
                                  className="card-title"
                                  style={{ alignSelf: "center" }}
                                >
                                  Pay With
                                </h5>

                                <img
                                  src={require("../../assets/images/logo-h.png")}
                                  alt="logo"
                                />
                                {/* <p
                                  className="card-text text-dark mt-md-2"
                                  style={{ fontWeight: "600" }}
                                >
                                  fee : 0₱
                                </p> */}
                              </div>
                            </div>
                            <ReCAPTCHA
                              ref={recaptchaRef}
                              size="invisible"
                              sitekey={capt}
                            />
                          </div>
                        </div>
                      ) : (
                        <ScaleLoader
                          style={{ textAlign: "center", display: "block" }}
                          width={7}
                          height={50}
                          //size={"150px"} this also works
                          color={"#fff"}
                        />
                      )}
                    </div>
                  </div>
                ) : !this.state.top ? (
                  <div
                    className="col-md-6  theme-text"
                    style={{
                      background: "linear-gradient(to left, #3a98d3,#52bafa87)",
                      border: " 1px solid #fff",
                      marginTop: "3rem",
                      borderRadius: "15px",
                    }}
                  >
                    <Insta
                      instadata={this.state.instares}
                      title={this.state.type}
                      securepage={this.state.securepage}
                    />
                  </div>
                ) : (
                  <div
                    className="col-md-6"
                    style={{
                      background: "linear-gradient(to left, #3a98d3,#52bafa87)",
                      border: " 1px solid #fff",
                      marginTop: "6rem",
                      borderRadius: "15px",
                    }}
                  >
                    <Topchek
                      title={this.state.type}
                      hash={this.state.hash}
                      api_key={this.state.apikey}
                      securepage={this.state.securepage}
                    />
                  </div>
                )}
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    } else {
      return <NoData />;
    }
  }
}
export default pushRoute(Home);
