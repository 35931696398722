import React, { Component } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import logo from "../assets/images/logo-h.png";
import { pushRoute } from "../common/pushRoute";

class CheckInsta extends Component {
  state = {};

  render() {
    const instadata = this.props;
    var merchant = instadata.instadata;
    return (
      <React.Fragment>
        <div className="row m-0 ">
          <div className=" card-font">
            <img src={logo} className="logo  m-auto" alt="logo" />
          </div>
        </div>
        <div className="row m-0 ">
          <div className="col-md-8 col-10 ">
            <div
              className="p-3 mb-2 bg-white rounded"
              style={{
                marginTop: "8rem",
                marginLeft: "6rem",
                marginRight: "9rem",
              }}
            >
              <div className="mt-2">
                <div className="row">
                  <div
                    className="col-md-12 d-md-flex"
                    style={{ padding: "0% 14% 0% 11%" }}
                  >
                    <div className="pull-left col-md-6 col-12">
                      <h5 className="theme-text">Name:</h5>
                      <h5>
                        Invoice No :
                        {/* {merchant ? merchant.invoice_number : "198699070"}{" "} */}
                      </h5>
                    </div>

                    <div className="pull-right sp-response   col-md-6 col-10">
                      <h5 className="theme-text">
                        {" "}
                        {merchant ? merchant.name : "New Merchant"}
                      </h5>
                      <h5>
                        {merchant ? merchant.invoice_number : "198699070"}
                      </h5>
                    </div>
                  </div>
                  <hr></hr>
                  <div
                    className="col-md-12 d-md-flex"
                    style={{ padding: "0% 14% 0% 11%" }}
                  >
                    <div className="pull-left col-md-6 col-12">
                      <h5 className="theme-text">TXN ID:</h5>
                      <h5>
                        Amount To Be Paid :
                        {/* {merchant ? merchant.invoice_number : "198699070"}{" "} */}
                      </h5>
                    </div>

                    <div className="pull-right sp-response   col-md-6 col-10">
                      <h6 className="theme-text">
                        {" "}
                        {merchant ? merchant.tid : "New Merchant"}
                      </h6>
                      <h5>
                        ₱{" "}
                        {parseFloat(merchant ? merchant.amount : 0).toFixed(2)}
                      </h5>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row text-center">
                    <h6>Insta Pay ID</h6>

                    <div className="col-md-8 m-auto">
                      <div className="input-group m-auto">
                        <input
                          disabled
                          onChange={this.handleChange}
                          value={merchant.instapay_id}
                          className="form-control text-center "
                        />
                      </div>
                      <div
                        style={{
                          color: "black",

                          fontSize: "0.7rem",
                        }}
                      >
                        <p
                          style={{
                            textDecoration: "underline",
                            fontWeight: "600",
                          }}
                        >
                          TRANSFER INSTRUCTIONS:
                        </p>
                        <p>
                          1. Copy the account number above (This is your Top
                          Wallet assigned account number).
                        </p>
                        <p>
                          2. Open your online bank app and click on the tab to
                          transfer funds through Instapay or Pesonet.
                        </p>
                        <p>
                          3. Fill in the required details and paste your
                          TopWallet account number in the ‘Beneficiary Account’
                          field.
                        </p>
                        <p>
                          4. In the ‘Beneficiary Bank’ field, look for and
                          select{" "}
                          <span style={{ color: "#3a9ad4", fontWeight: "500" }}>
                            NETBANK – CUOBPHM2XXX.
                          </span>
                        </p>
                        <p>
                          5. Enter the amount that is to be transferred to
                          TopWallet.
                        </p>
                        <p>
                          6. Click on the Instapay or Pesonet button to complete
                          the transfer.
                        </p>
                        <p
                          style={{
                            textDecoration: "underline",
                            fontWeight: "600",
                          }}
                        >
                          Please note:
                        </p>
                        <p>
                          7. Funds transfer via Instapay occurs instantly – PHP
                          50,000 or less per transaction.
                        </p>
                        <p>
                          8. Funds transfer via Pesonet will be credited at the
                          end of the day or the following day if used after the
                          bank cutoff. – For transactions above PHP 50,000.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default pushRoute(CheckInsta);
