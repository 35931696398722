import React, { Component } from "react";
import "./pop.css";
import { pushRoute } from "./pushRoute";

class PopUp extends Component {
  state = {};

  returnurl = () => {
    var data = this.props?.data;
    if (data.status === "Success") {
      window.location.href = `${data.success_url}?invoice_number=${data.invoice_number}&status=${data.status}&tid=${data.tid}`;
    } else if (data.status === "Failed") {
      window.location.href = `${data.return_url}?invoice_number=${data.invoice_number}&status=${data.status}&tid=${data.tid}`;
    }
  };
  render() {
    var data = this.props?.data;

    return (
      <div className="bodyy">
        <div className="pop-card">
          <div
            className="checkk"
            style={{
              borderRadius: "200px",
              height: "200px",
              width: "200px",
              background: "#F8FAF5",
              margin: "0 auto",
            }}
          >
            {data.status === "Success" ? (
              <i className="checkmark">✓</i>
            ) : (
              <i
                className="checkk fa fa-times text-danger "
                style={{
                  margin: "auto",
                  lineHeight: "12rem",
                }}
              ></i>
            )}
          </div>
          <h1 className="pop-h">Payment {data?.status}</h1>
          <p className="pop-p">Invoice :{data?.invoice_number}</p>
          <p className="pop-p"> Txnid :{data?.tid}</p>
          <p className="pop-p"> Amount:{data?.amount} ₱</p>
        </div>
        <div>
          <button className="btn bg-warning text-dark" onClick={this.returnurl}>
            Return to Merchant
          </button>
        </div>
      </div>
    );
  }
}

export default pushRoute(PopUp);
