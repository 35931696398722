import React, { Component } from "react";
import Header from "../../common/header";
import { pushRoute } from "../../common/pushRoute";
class Insta extends Component {
  state = {};
  componentDidMount() {
    window.history.replaceState(null, null, "/");
  }
  copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      await this.setState({ text: "InstaPay ID Copied!" });
    } catch (err) {
      await this.setState({ text: "Failed to copy!" });
    }
  };

  render() {
    const merchant = this.props?.instadata;
    return (
      <React.Fragment>
        <div className=" mb-2 insta_mob">
          <div className="mt-2">
            <div className="row">
              <div className="col-md-12 d-md-flex ">
                <h4 className="text-center theme-text col-md-6 col-12 ">
                  InstaPay Details
                </h4>
                <h6 className="theme-text col-md-6 offset-md-4 mob_text_center">
                  Invoice No :{merchant ? merchant.invoice_number : "198699070"}
                </h6>
              </div>
              <hr></hr>
              <div
                className="col-md-12 d-md-flex col-6 d-inline-flex"
                // style={{ padding: "0% 14% 0% 11%" }}
              >
                <div className="pull-left col-md-6 col-10">
                  <h6 className="theme-text">Name:</h6>
                  {/* <h6 className="theme-text">
                    Invoice No :
                    
                  </h6> */}
                </div>

                <div className="pull-right sp-response   col-md-6 col-10">
                  <h6 className="theme-text text-uppercase">
                    {" "}
                    {merchant ? merchant.name : "New Merchant"}
                  </h6>
                  {/* <h6 className="theme-text">
                    {merchant ? merchant.invoice_number : "198699070"}
                  </h6> */}
                </div>
              </div>

              <div
                className="col-md-12 d-md-flex col-11 d-inline-flex"
                // style={{ padding: "0% 14% 0% 11%" }}
              >
                <div className="pull-left col-md-6 col-5">
                  <h6 className="theme-text">TXN ID:</h6>
                  <h6 className="theme-text">Amount:</h6>
                </div>

                <div className="pull-right sp-response   col-md-6 col-10">
                  <h6 className="theme-text" style={{ fontSize: "14.5px" }}>
                    {" "}
                    {merchant ? merchant?.tid : "No"}
                  </h6>
                  <h6 className="theme-text">
                    ₱ {parseFloat(merchant ? merchant.amount : 0).toFixed(2)}
                  </h6>
                </div>
              </div>
              <hr className="m-0 p-0"></hr>

              <div className="row d-md-flex ">
                <div className="col-md-12 d-md-flex my-2 d-inline-flex">
                  <h4 className=" col-md-6 my-3 col-5">InstaPay ID</h4>
                  <div className="input-group col-md-3 m-auto col-6">
                    <input
                      disabled
                      onChange={this.handleChange}
                      value={merchant.instapay_id}
                      className="form-control text-center "
                      style={{ height: "2.5rem" }}
                    />
                    <span
                      className="input-group-text"
                      id="basic-addon2"
                      onClick={() => {
                        this.copyToClipBoard(merchant?.instapay_id);
                      }}
                    >
                      <i className="fa fa-copy"></i>
                    </span>
                  </div>
                </div>
                <div className="col-md-12 m-auto">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "white",
                      fontWeight: "600",
                    }}
                  >
                    <small className="text-center">{this.state?.text}</small>
                  </div>
                  <div className="insta_mob_text">
                    <p
                      style={{
                        textDecoration: "underline",
                        fontWeight: "600",
                      }}
                    >
                      TRANSFER INSTRUCTIONS:
                    </p>
                    <div>
                      1. Copy the account number above (This is your Top Wallet
                      assigned account number).
                    </div>
                    <p>
                      2. Open your online bank app and click on the tab to
                      transfer funds through Instapay.
                    </p>
                    <p>
                      3. Fill in the required details and paste your TopWallet
                      account number in the ‘Beneficiary Account’ field.
                    </p>
                    <p>
                      4. In the ‘Beneficiary Bank’ field, look for and select{" "}
                      <span style={{ color: "white", fontWeight: "500" }}>
                        NETBANK – CUOBPHM2XXX.
                      </span>
                    </p>
                    <p>
                      5. Enter the amount that is to be transferred to
                      TopWallet.
                    </p>
                    <p>
                      6. Click on the Instapay or Pesonet button to complete the
                      transfer.
                    </p>
                    <p
                      style={{
                        textDecoration: "underline",
                        fontWeight: "600",
                      }}
                    >
                      Please note:
                    </p>
                    <p>
                      7. Funds transfer via Instapay occurs instantly – PHP
                      50,000 or less per transaction.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default pushRoute(Insta);
