import http from "./httpservice";
import helpr from "./cryptos";
var atob = require("atob");

const apiEndpoint = "/merchtrans";

export async function gettexn(obj) {
  const validaobj = helpr.encryptobj(obj);

  const { data } = await http.post(apiEndpoint + "/reset", { enc: validaobj });
  return helpr.decryptobj(data);
}

export default {
  gettexn,
};
