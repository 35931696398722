import React, { Component } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import auth from "../services/authservice";
import ScaleLoader from "react-spinners/ScaleLoader";
import success from "../assets/images/success.gif";
import NoData from "./nodata";
import { publicIpv4 } from "public-ip";
import queryString from "query-string";

import logo from "../assets/images/logo-h.png";
// "./assets/images/logo-h.png";

import { pushRoute } from "../common/pushRoute";
const axios = require("axios");
// const queryString = require("query-string");
const url = require("url");
// const publicIp = require("public-ip");
class MainComponent extends Component {
  state = {
    otpview: false,
    payview: false,
    mobile: "",
    otp: "",
    balance: 0,
    parseddata: {},
    loader: false,
    securepage: false,
    successimage: true,
    ip: "",
  };
  componentDidMount = async () => {
    const IP = await publicIpv4({
      fallbackUrls: ["https://ifconfig.co/ip"],
    });

    await this.setState({ ip: IP });
    // (async () => {
    //   const myip = await publicIp.v4({
    //     fallbackUrls: ["https://ifconfig.co/ip"],
    //   });
    // })();

    //=> '?foo=bar'

    var location_search =
      "http://localhost:3000?hash=hkvhhdbsh&apikey=jkbvfbvbdfbhfdbhj";
    const parsed = queryString.parse(location_search);

    // if (this.props.location.search) {
    //   var utt =
    //     "http://localhost:3000/?hash=hkvhhdbsh&apikey=jkbvfbvbdfbhfdbhj";
    //   const parsed = queryString.parse(utt);
    //   console.log(parsed, "props");
    //   // const decrypted = await auth.decrypted(parsed.stro);
    //   // console.log();
    //   // await this.setState({ parseddata: decrypted });
    //   // window.history.replaceState(null, null, window.location.pathname);
    // } else {
    //   this.setState({ securepage: false });
    // }
  };
  mobileproceed = async () => {
    this.setState({ loader: true });
    toast.configure();
    if (this.state.mobile === "") {
      this.setState({ loader: false });
      toast.error("Please Enter a Mobile Number", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (this.state.mobile.length < 10 || this.state.mobile.length > 10) {
      this.setState({ loader: false });
      toast.error("Please Enter a Valid Mobile Number", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(async () => {
        await this.setState({ mobile: "" });
      }, 2000);
    } else {
      try {
        const { data } = await auth.sendphone(this.state.mobile);
        if (data.success) {
          await this.setState({ otpview: true, loader: false });
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data, {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.setState({ loader: false });
        }
      }
    }
  };
  otpverify = async () => {
    this.setState({ loader: true });
    toast.configure();
    if (this.state.otp === "") {
      this.setState({ loader: false });
      toast.error("Please Enter a OTP", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (this.state.otp.length < 6 || this.state.otp.length > 6) {
      this.setState({ loader: false });
      toast.error("Please Enter a Valid OTP", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(async () => {
        await this.setState({ otp: "" });
      }, 2000);
    } else {
      const obj = {
        phone: this.state.mobile,
        otp: this.state.otp,
      };
      try {
        const { data } = await auth.validateotp(obj);

        if (data.Peso) {
          await this.setState({
            payview: true,
            balance: data.Peso,
            loader: false,
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data, {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.setState({ loader: false });
        }
      }
    }
  };
  handleChange = async (e) => {
    var val = e.target.value.replace(/[^0-9]/g, "");

    await this.setState({ [e.target.name]: val });
  };

  proceedpay = async () => {
    await this.setState({ loader: true });
    if (
      parseFloat(this.state.balance) < parseFloat(this.state.parseddata.amount)
    ) {
      this.setState({ loader: false });
      toast.error("Insufficient Funds", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const obj = {
        tid: this.state.parseddata.tid,
        amount: this.state.parseddata.amount,
        merchant_invoice: this.state.parseddata.merchant_invoice,
        user_phone: this.state.mobile,
        ip: this.state.ip,
      };
      try {
        const { data } = await auth.proceedpay(obj);

        if (data.success) {
          setTimeout(async () => {
            await this.setState({
              successimage: false,
              loader: false,
            });
          }, 3000);

          setTimeout(async () => {
            const txnstatus = await auth.gettransactionstatus(
              this.state.parseddata.tid
            );
            if (txnstatus.data.status) {
              const postobj = {
                merchant_invoice: this.state.parseddata.merchant_invoice,
                txnid: this.state.parseddata.tid,
                amount: this.state.parseddata.amount,
                status: txnstatus.data.status,
              };

              await axios.post(this.state.parseddata.successurl, postobj);
              setTimeout(() => {
                window.location.href = url.format({
                  pathname: this.state.parseddata.returnurl,
                  query: postobj,
                });
              }, 1000);

              if (txnstatus.success) {
                await this.setState({
                  successimage: false,
                });
              }
            }
          }, 6000);
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data, {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.setState({ loader: false });
        }
      }
    }
  };
  returnmerchant = async () => {
    try {
      await this.setState({ loader: true });
      setTimeout(async () => {
        const txnstatus = await auth.gettransactionstatus(
          this.state.parseddata.tid
        );
        if (txnstatus.data.status) {
          const postobj = {
            merchant_invoice: this.state.parseddata.merchant_invoice,
            txnid: this.state.parseddata.tid,
            amount: this.state.parseddata.amount,
            status: txnstatus.data.status,
          };
          await axios.post(this.state.parseddata.successurl, postobj);
          setTimeout(() => {
            window.location.href = url.format({
              pathname: this.state.parseddata.returnurl,
              query: postobj,
            });
          }, 1000);
          if (txnstatus.success) {
            await this.setState({
              successimage: false,
            });
          }
        }
      }, 5000);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.setState({ loader: false });
      }
    }
  };
  backurl = async () => {
    const postob = {
      merchant_invoice: this.state.parseddata.merchant_invoice,
      txnid: this.state.parseddata.tid,
      amount: this.state.parseddata.amount,
      status: "Cancelled",
    };

    window.location.href = url.format({
      pathname: this.state.parseddata.returnurl,
      query: postob,
    });
  };
  render() {
    const { merchant_name, amount, merchant_invoice, tid } =
      this.state.parseddata;
    if (!this.state.securepage) {
      return (
        <React.Fragment>
          <div className="row m-0 ">
            <div className="col-md-10 col-10 m-auto">
              <div className=" card-font">
                <img src={logo} className="logo mt-4" alt="logo" />
              </div>
              <div className="mt-5 p-4 mb-2 bg-white rounded">
                <div className="row">
                  <div className="col-md-12 d-md-flex">
                    <div
                      onClick={this.backurl}
                      className="pull-left hand col-12 col-md-6"
                    >
                      <p className="text-left font12   ">
                        <i
                          className="fa fa-angle-left mr-3"
                          style={{ fontSize: "20px", fontWeight: "900" }}
                        >
                          {" "}
                        </i>
                        <span
                          className=" ml-md-5 mx-5"
                          style={{ fontSize: "18px", fontWeight: "500" }}
                        >
                          Go Back
                        </span>
                      </p>
                    </div>
                    <div className="pull-right sp-response text-md-end text-center  col-md-6 col-12">
                      <p>
                        <span className="theme-text">TXN ID</span> :{" "}
                        {tid ? tid : "TJBSD1232JKN"}
                      </p>
                    </div>
                  </div>
                </div>

                <hr></hr>
                <div className="mt-2">
                  <div className="row">
                    <div className="col-md-12 d-md-flex">
                      <div className="pull-left col-md-6 col-12">
                        <h4 className="theme-text">
                          {merchant_name ? merchant_name : "New Merchant"}
                        </h4>
                        <h6>
                          Invoice No :{" "}
                          {merchant_invoice ? merchant_invoice : "198699070"}
                        </h6>
                      </div>

                      <div className="pull-right sp-response text-md-end  col-md-6 col-10 mt-3">
                        <h5 className="theme-text">Amount to be Paid</h5>
                        <h5>
                          ₱ {parseFloat(amount ? amount : 250).toFixed(2)}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <div className="bg-white p-4 rounded">
                  <h6>Pay With TopWallet</h6>
                </div>
                <div className="p-5 bg-mobile">
                  {!this.state.otpview ? (
                    <div className="row">
                      <div className="col-md-8">
                        <div className="form-group">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text bg-themecolor text-white">
                                +63
                              </span>
                            </div>
                            <input
                              onChange={this.handleChange}
                              value={this.state.mobile}
                              name="mobile"
                              type="text"
                              maxLength={10}
                              className="form-control custom-input"
                              id="mobilenumber"
                              placeholder="Mobile Number Registered With TopWallet"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        {!this.state.loader ? (
                          <button
                            onClick={this.mobileproceed}
                            className="btn btn-md btn-primary theme-button"
                            type="button"
                          >
                            Proceed
                          </button>
                        ) : (
                          <ScaleLoader
                            width={7}
                            height={50}
                            //size={"150px"} this also works
                            color={"#fff"}
                          />
                        )}
                      </div>
                    </div>
                  ) : !this.state.payview ? (
                    <div>
                      <h5 className="mb-3">
                        Enter OTP Sent To Mobile Number +63 {this.state.mobile}
                      </h5>
                      <div className="row">
                        <div className="col-md-8">
                          <div className="form-group">
                            <input
                              onChange={this.handleChange}
                              value={this.state.otp}
                              name="otp"
                              type="text"
                              maxLength={6}
                              className="form-control custom-input"
                              id="OTP"
                              placeholder="Enter OTP"
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          {!this.state.loader ? (
                            <button
                              onClick={this.otpverify}
                              className="btn btn-md btn-primary theme-button"
                              type="button"
                            >
                              Verify
                            </button>
                          ) : (
                            <ScaleLoader
                              width={7}
                              height={50}
                              //size={"150px"} this also works
                              color={"#fff"}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ) : this.state.successimage ? (
                    <div>
                      <div className="row">
                        <div className="col-md-9">
                          <h4>TopWallet Balance</h4>
                          <h6>
                            Available Peso Balance : ₱ {this.state.balance}
                          </h6>
                        </div>
                        <div className="col-md-3">
                          {!this.state.loader ? (
                            <button
                              onClick={this.proceedpay}
                              className="btn btn-md btn-primary theme-button mt-2"
                              type="button"
                            >
                              Pay &nbsp;&nbsp; ₱{parseFloat(amount).toFixed(2)}
                            </button>
                          ) : (
                            <ScaleLoader
                              width={7}
                              height={50}
                              //size={"150px"} this also works
                              color={"#fff"}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <img
                        className="m-auto"
                        style={{ width: "200px", display: "flex" }}
                        src={success}
                        alt="success"
                      />
                      <div
                        className="mt-4"
                        style={{ margin: "auto", display: "flex" }}
                      >
                        {!this.state.loader ? (
                          <button
                            onClick={() => this.returnmerchant()}
                            className="btn btn-md m-auto theme-color btn-merchant"
                            type="button"
                          >
                            Return To Merchant
                          </button>
                        ) : (
                          <div className="m-auto">
                            <ScaleLoader
                              width={7}
                              height={50}
                              //size={"150px"} this also works
                              color={"#fff"}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return <NoData />;
    }
  }
}

export default pushRoute(MainComponent);
