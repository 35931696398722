import React, { Component } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import auth from "../services/authservice";
import ScaleLoader from "react-spinners/ScaleLoader";
import success from "../assets/images/success.gif";
import NoData from "./nodata";
import { publicIpv4 } from "public-ip";
import queryString from "query-string";

import logo from "../assets/images/logo-h.png";
import instapy from "../assets/images/instapay.png";

import { pushRoute } from "../common/pushRoute";
import { getinsta } from "../services/checkoutService";
import Chkinsta from "./chkinsta";
const url = require("url");
const axios = require("axios");
class CheckOut extends Component {
  state = {
    otpview: false,
    payview: false,
    mobile: "",
    otp: "",
    balance: 0,
    instares: {},
    loader: false,
    securepage: false,
    successimage: true,
    payopt: false,
    apikey: "",
    hash: "",
    ip: "",
    pop: false,
  };
  componentDidMount = async () => {
    const IP = await publicIpv4({
      fallbackUrls: ["https://ifconfig.co/ip"],
    });

    await this.setState({ ip: IP });

    const parsed = queryString.parse(this.props.location.search);

    if (parsed && parsed?.apikey && parsed?.hash) {
      this.setState({
        securepage: false,
        // apikey: encodeURIComponent(parsed?.apikey.toString("base64")),
        // hash: encodeURIComponent(parsed?.hash.toString("base64")),
      });
    } else {
      this.setState({ securepage: true });
    }
  };

  handleChange = async (e) => {
    var val = e.target.value.replace(/[^0-9]/g, "");

    await this.setState({ [e.target.name]: val });
  };

  instapaychk = async (type) => {
    this.setState({ loader: true, type: type });
    try {
      var instaobj = {
        type: type,
        hash: this.state.hash,
        api_key: this.state.apikey,
      };

      const res = {
        amount: 1000,
        invoice_number: "1234123",
        success_url: "https://codegene.io",
        return_url: "htttps://google.com",
        timestamp: 1676641417518,
        userid: "OVBHSXP23049516@TOP",
        instapay_id: "90023999999998",
        tid: "INSTA@D89M8R1676694691594",
        name: "cg business",
      };
      //   await getinsta(instaobj);

      if (res) {
        this.setState({ pop: true, loader: false, instares: res });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data, {
          position: toast.POSITION.TOP_RIGHT,
        });
        await this.setState({ loader: false });
      }
    }
  };

  render() {
    const merchant = {
      amount: 1000,
      invoice_number: "1234123",
      success_url: "https://codegene.io",
      return_url: "htttps://google.com",
      timestamp: 1676641417518,
      userid: "OVBHSXP23049516@TOP",
      instapay_id: "90023999999998",
      tid: "INSTA@D89M8R1676694691594",
      name: "cg business",
    };
    // this.state.instares;

    if (this.state.securepage) {
      return (
        <React.Fragment>
          {this.state.pop ? (
            <div className="row m-0 ">
              <div className=" card-font">
                <img src={logo} className="logo mt-4 m-auto" alt="logo" />
              </div>
              <div className="col-md-4 col-12 pay-bg">
                <div className="p-1 mb-2 rounded">
                  <div className="row m-0 p-0">
                    {!this.state.loader ? (
                      <div>
                        <p className=" text-white tit_text mt-5">
                          Select an option you would like to proceed
                        </p>
                        <div className="offset-md-0">
                          <div className="col-12 col-md-9 mb-md-3 mb-2 text-center">
                            <div
                              className=" bg-white insta_card"
                              onClick={() => this.instapaychk("insta")}
                            >
                              <h5
                                className="card-title"
                                style={{ alignSelf: "center" }}
                              >
                                Pay With
                              </h5>
                              <img src={instapy} alt="logo" />
                              <p
                                className="card-text text-dark mt-md-2"
                                style={{ fontWeight: "600" }}
                              >
                                fee : 15₱
                              </p>
                            </div>
                          </div>
                          <div className="col-12 col-md-9 mb-md-3 mb-3 text-center">
                            <div className="bg-white tw_card" style={{}}>
                              <h5
                                className="card-title"
                                style={{ alignSelf: "center" }}
                              >
                                Pay With
                              </h5>
                              <img src={logo} alt="logo" />
                              <p
                                className="card-text text-dark mt-md-2"
                                style={{ fontWeight: "600" }}
                              >
                                fee : 0₱
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <ScaleLoader
                        width={7}
                        height={50}
                        //size={"150px"} this also works
                        color={"#fff"}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Chkinsta instadata={merchant} title={this.state.type} />
          )}
        </React.Fragment>
      );
    } else {
      return <NoData />;
    }
  }
}

export default pushRoute(CheckOut);
