import React, { Component } from "react";
import wrongimage from "../assets/images/wrongimage.gif";
import { pushRoute } from "../common/pushRoute";

class NoData extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="container text-center">
          <img src={wrongimage} className="error_img" alt="Snow" />

          <div className="centered text-dark">{this.props?.error}</div>
        </div>
        {/* <div className="mt-5 p-3 rounded">
          <div className="mt-2">
            <div className="row">
              <div className="col-md-12">
                <img
                  className="m-auto wrongimage"
                  src={wrongimage}
                  alt="wrongimage"
                />
                jlkjkjkljlkjjkjkljk
              </div>
            </div>
          </div>
        </div> */}
      </React.Fragment>
    );
  }
}

export default pushRoute(NoData);
