import React, { Component } from "react";
class Header extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <header
          id="header"
          className="fixed-top header-transparent header-scrolled bg-white p-2"
        >
          <div className="container-fluid mt-2">
            <div className="logo  col-md-2 m-auto ">
              <a aria-current="page" className="active" href="/">
                <img
                  src={require("../assets/images/logo-h.png")}
                  alt=""
                  style={{
                    width: "100%",
                  }}
                />
              </a>
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default Header;
