import React, { Component } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { toast } from "react-toastify";
import ReactCountdownClock from "react-countdown-clock";
import { pushRoute } from "../../common/pushRoute";
import {
  resendOTP,
  topwalletotppay,
  topwalletphonepay,
} from "../../services/checkoutService";
import PopUp from "../../common/pop";

class Topcheck extends Component {
  state = { resend: false, securepage: this.props?.securepage };
  async componentDidMount() {
    window.history.replaceState(null, null, "/");
  }

  handleChange = async (e) => {
    var val = e.target.value.replace(/[^0-9]/g, "");

    await this.setState({ [e.target.name]: val });
  };
  mobileproceed = async () => {
    this.setState({ loader: true });

    if (this.state.mobile === "" || !this.state.mobile?.length) {
      this.setState({ loader: false });
      toast.error("Please Enter a Mobile Number", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      try {
        const obj = {
          type: this.props.title,
          phone: this.state.mobile,
          hash: this.props.hash,
          api_key: this.props.api_key,
        };

        const data = await topwalletphonepay(obj);

        if (data) {
          await this.setState({
            otpview: true,
            loader: false,
            topdata: data,
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data, {
            position: toast.POSITION.TOP_RIGHT,
          });

          this.setState({ loader: false, securepage: true });
        }
        this.setState({ loader: false, securepage: true });
      }
    }
  };
  otpverify = async () => {
    this.setState({ loader: true });

    if (this.state.otp === "" || !this.state.otp?.length) {
      this.setState({ loader: false });
      toast.error("Please Enter OTP", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const topobj = {
        otp: this.state.otp,
        merchant_tid: this.state?.topdata?.merchant_tid,
      };

      try {
        const data = await topwalletotppay(topobj);

        if (data) {
          //   if (data.status === "Success") {
          //     window.location.href = `${data.success_url}?invoice_number=${data.invoice_number}&status=${data.status}&tid=${data.tid}`;
          //   } else if (data.status === "Failed") {
          //     window.location.href = `${data.return_url}?invoice_number=${data.invoice_number}&status=${data.status}&tid=${data.tid}`;
          //   }
          await this.setState({
            //   payview: true,
            loader: false,
            tpdat: data,
            pop: true,
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data, {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.setState({ loader: false, securepage: true });
        }
        this.setState({ loader: false, securepage: true });
      }
    }
  };
  finishcount = () => {
    this.setState({ resend: true });
  };
  otpResend = async () => {
    await this.setState({ resend: false });
    try {
      var obj = {
        tid: this.state?.topdata?.merchant_tid,
      };
      const res = await resendOTP(obj);

      if (res) {
        toast.success(res.success, {
          position: toast.POSITION.TOP_RIGHT,
        });
        await this.setState({ resend: false });
      }
    } catch (error) {
      await this.setState({ resend: true });
      toast.error(error.response.data, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  backurl = () => {
    var data = this.state.topdata;
    window.location.href = `${data.return_url}?invoice_number=${data.invoice_number}&status=Failed`;
  };
  render() {
    const merchant = this.state?.topdata;

    return (
      <React.Fragment>
        {this.state.pop ? (
          <PopUp data={this.state.tpdat} />
        ) : (
          <div className=" mb-2">
            <div className="mt-2">
              <div className="row">
                {!this.state.otpview ? (
                  <div className="row">
                    <div className=" p-md-4 rounded text-center text-white">
                      <h6>Enter Phone Number Registerd With TopWallet</h6>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <div className="input-group ph_input mb-2">
                          <div className="input-group-prepend">
                            <span className="input-group-text bg-themecolor text-white">
                              +63
                            </span>
                          </div>
                          <input
                            onChange={this.handleChange}
                            value={this.state.mobile}
                            name="mobile"
                            type="text"
                            maxLength={10}
                            className="form-control custom-input"
                            id="mobilenumber"
                            placeholder="Enter Mobile Number without 0"
                          />
                        </div>
                        <span className="text-center text-white  d-md-none my-2">
                          Note: Enter Mobile Number without 0
                        </span>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 text-center  m-auto">
                      {!this.state.loader ? (
                        <button
                          style={{
                            fontWeight: "600",
                            border: "1px solid white",
                            fontSize: "18px",
                          }}
                          onClick={this.mobileproceed}
                          className="btn  text-white bg-warning  w-100 rounded-pill"
                          type="button"
                        >
                          Proceed
                        </button>
                      ) : (
                        <ScaleLoader
                          width={7}
                          height={50}
                          //size={"150px"} this also works
                          color={"#fff"}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="p-4">
                    <div className="row">
                      <div className="col-md-12 col-10 d-md-flex ">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={this.backurl}
                        >
                          <i
                            className="fa fa-long-arrow-left bg-dark badge round-pill text-white mx-2"
                            aria-hidden="true"
                          >
                            {" "}
                            Back
                          </i>
                        </span>
                        <h6 className="theme-text col-md-6 offset-md-2 mob_text_center">
                          Invoice No :
                          {merchant ? merchant.invoice_number : "198699070"}
                        </h6>
                      </div>
                      <hr className="m-0 p-0 d-none d-md-block"></hr>
                      <div className="col-md-12 d-md-flex col-6 d-inline-flex">
                        <div className="pull-left text-center col-md-6 col-10">
                          <h6 className="theme-text">Name:</h6>
                        </div>

                        <div className="pull-right sp-response   col-md-6 col-10">
                          <h6 className="theme-text text-uppercase">
                            {" "}
                            {merchant ? merchant.name : "New Merchant"}
                          </h6>
                        </div>
                      </div>

                      <div className="col-md-12 d-md-flex col-11 d-inline-flex">
                        <div className="pull-left text-center col-md-6 col-5">
                          <h6 className="theme-text">TXN ID:</h6>
                          <h6 className="theme-text">Amount:</h6>
                        </div>

                        <div className="pull-right sp-response   col-md-6 col-10">
                          <h6 className="theme-text">
                            {" "}
                            {merchant ? merchant?.merchant_tid : "New Merchant"}
                          </h6>
                          <h6 className="theme-text">
                            ₱{" "}
                            {parseFloat(merchant ? merchant.amount : 0).toFixed(
                              2
                            )}
                          </h6>
                        </div>
                      </div>
                      <hr className="m-0 p-0"></hr>
                    </div>
                    <div className="row">
                      <h6 className="my-3 text-center">
                        Enter OTP Sent To Mobile Number +63 {this.state?.mobile}
                      </h6>
                      <div className="col-md-12">
                        <div className="form-group">
                          <div className="input-group ph_input">
                            <input
                              onChange={this.handleChange}
                              value={this.state.otp}
                              name="otp"
                              type="text"
                              maxLength={6}
                              className="form-control custom-input"
                              id="mobilenumber"
                              placeholder="Enter OTP"
                            />
                          </div>
                        </div>
                      </div>
                      {/* resend otp */}

                      <div
                        className="col-md-12 col-12 d-inline-flex  d-md-flex"
                        style={{ justifyContent: "center" }}
                      >
                        {this.state.resend ? (
                          <>
                            <span className="auth-link text-white  ">
                              Didn't receive the otp?
                            </span>{" "}
                            &nbsp; &nbsp;
                            <span
                              className="text-white cursor-pointer"
                              onClick={() => this.otpResend()}
                            >
                              {" "}
                              Click here
                            </span>
                          </>
                        ) : (
                          <>
                            <h5
                              className="text-white"
                              style={{
                                fontSize: "1rem",
                                margin: "0.7rem 0.5rem  0.5rem 2rem ",
                              }}
                            >
                              {" "}
                              Code Expires Within{" "}
                            </h5>
                            <ReactCountdownClock
                              seconds={60}
                              showMilliseconds={false}
                              color="#fff"
                              // strokeColor="#3a9ad4"
                              alpha={0.9}
                              size={35}
                              weight={3}
                              onComplete={this.finishcount}
                            />
                          </>
                        )}
                      </div>

                      <div className="col-md-4 col-6  m-auto">
                        {!this.state.loader ? (
                          <button
                            style={{
                              fontWeight: "600",
                              border: "1px solid white",
                              fontSize: "18px",
                            }}
                            onClick={this.otpverify}
                            className="btn  text-white bg-warning  w-100 rounded-pill"
                            type="button"
                          >
                            Proceed
                          </button>
                        ) : (
                          <ScaleLoader
                            width={7}
                            height={50}
                            //size={"150px"} this also works
                            color={"#fff"}
                            style={{ textAlign: "center", display: "block" }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default pushRoute(Topcheck);
